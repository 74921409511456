import anime from 'animejs';

type Props = {
    "client:load": boolean,
    link : string,
    text : string
}

export default function Button2(prop: Props) {
    return (
        <a href={prop.link} className="group">
        
        <div className={"relative border-blue-500 bg-blue-900 border-[1px]  rounded-md w-[90dvw] sm:w-[70dvw] md:max-w-[480px] sm:min-w-[320px] z-20 group overflow-hidden before:absolute before:bottom-0 before:left-0 before:block before:h-full before:w-full before:-translate-x-full before:transition-transform hover:before:translate-x-0 before:bg-blue-800/80 duration-500"}
          onMouseEnter={
            (e) => {
                anime({
                    targets: e.currentTarget,
                    translateX: [0,100,75,50,25,0],
                    duration: 300,
                    easing: 'easeOutElastic',
                });
            }
        }>
        <div className="relative md:text-xl text-base gap-10 flex items-center px-5 text-slate-100 ">
        <p className="z-3 text-lg mt-0.5 flex-1 text-center">{prop.text}</p>
        <p className='group-hover:translate-x-2 transition-transform duration-500'>→</p>
        </div>
        
        </div>
        </a>
    )
}
